import React from 'react';
import PropTypes from 'prop-types';

import ItemDataFetcher from '../../api/object-admin/ItemDataFetcher';
import SelectItemType from '../EditForm/Inputs/SelectItemType';
import TargetSelect from '../Utils/TargetSelect';

import UITeaserBox from '../UITeaserBox';

import './styles.scss';

/**
 * @visibleName 4 variable Teaser Boxes
 */
class TeaserBoxList extends React.Component {
    render() {
        const {
            isVisibleItem1, isVisibleItem2, isVisibleItem3, isVisibleItem4,

            itemType1, itemId1, url1, targetUrl1,
            itemType2, itemId2, url2, targetUrl2,
            itemType3, itemId3, url3, targetUrl3,
            itemType4, itemId4, url4, targetUrl4,

            imgSrc1, imgAlt1, imgTitle1, title1, text1, linkText1,
            imgSrc2, imgAlt2, imgTitle2, title2, text2, linkText2,
            imgSrc3, imgAlt3, imgTitle3, title3, text3, linkText3,
            imgSrc4, imgAlt4, imgTitle4, title4, text4, linkText4,

            language, season,
        } = this.props;
        const className = 'teaser-box-listing';
        return (
            <div className={className}>
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xl-3 g-col-lg-3 g-col-md-2 g-col-sm-2">
                            {
                                isVisibleItem1 && (itemType1 ? (
                                    <ItemDataFetcher
                                        itemType={itemType1}
                                        itemId={itemId1}
                                        language={language}
                                        season={season}
                                    >
                                        <UITeaserBox url={url1} target={'_self'} />
                                    </ItemDataFetcher>
                                ) : (
                                    <UITeaserBox
                                        imgSrc={imgSrc1}
                                        imgAlt={imgAlt1}
                                        imgTitle={imgTitle1}
                                        title={title1}
                                        text={text1}
                                        linkText={linkText1}
                                        url={url1}
                                        target={targetUrl1}
                                    />
                                ))
                            }
                        </div>

                        <div className="g-col-xl-3 g-col-lg-3 g-col-md-2 g-col-sm-2">
                            {
                                isVisibleItem2 && (itemType2 ? (
                                    <ItemDataFetcher
                                        itemType={itemType2}
                                        itemId={itemId2}
                                        language={language}
                                        season={season}
                                    >
                                        <UITeaserBox url={url2} target={'_self'} />
                                    </ItemDataFetcher>
                                ) : (
                                    <UITeaserBox
                                        imgSrc={imgSrc2}
                                        imgAlt={imgAlt2}
                                        imgTitle={imgTitle2}
                                        title={title2}
                                        text={text2}
                                        linkText={linkText2}
                                        url={url2}
                                        target={targetUrl2}
                                    />
                                ))
                            }
                        </div>

                        <div className="g-col-xl-3 g-col-lg-3 g-col-md-2 g-col-sm-2">
                            {
                                isVisibleItem3 && (itemType3 ? (
                                    <ItemDataFetcher
                                        itemType={itemType3}
                                        itemId={itemId3}
                                        language={language}
                                        season={season}
                                    >
                                        <UITeaserBox url={url3} target={'_self'} />
                                    </ItemDataFetcher>
                                ) : (
                                    <UITeaserBox
                                        imgSrc={imgSrc3}
                                        imgAlt={imgAlt3}
                                        imgTitle={imgTitle3}
                                        title={title3}
                                        text={text3}
                                        linkText={linkText3}
                                        url={url3}
                                        target={targetUrl3}
                                    />
                                ))
                            }
                        </div>

                        <div className="g-col-xl-3 g-col-lg-3 g-col-md-2 g-col-sm-2">
                            {
                                isVisibleItem4 && (itemType4 ? (
                                    <ItemDataFetcher
                                        itemType={itemType4}
                                        itemId={itemId4}
                                        language={language}
                                        season={season}
                                    >
                                        <UITeaserBox url={url4} target={'_self'} />
                                    </ItemDataFetcher>
                                ) : (
                                    <UITeaserBox
                                        imgSrc={imgSrc4}
                                        imgAlt={imgAlt4}
                                        imgTitle={imgTitle4}
                                        title={title4}
                                        text={text4}
                                        linkText={linkText4}
                                        url={url4}
                                        target={targetUrl4}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TeaserBoxList.formFields = Object.assign({}, {
    itemType1: <SelectItemType id={'itemType1'} options={['activity']} />,
    itemId1: <input />,
    url1: <input />,
    targetUrl1: <TargetSelect />,

    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,
    title1: <input />,
    linkText1: <input />,
    text1: <input />,
    //
    itemType2: <SelectItemType id={'itemType2'} options={['activity']} />,
    itemId2: <input />,
    url2: <input />,
    targetUrl2: <TargetSelect />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,
    title2: <input />,
    linkText2: <input />,
    text2: <input />,
    //
    itemType3: <SelectItemType id={'itemType3'} options={['activity']} />,
    itemId3: <input />,
    url3: <input />,
    targetUrl3: <TargetSelect />,

    imgSrc3: <input />,
    imgAlt3: <input />,
    imgTitle3: <input />,
    title3: <input />,
    linkText3: <input />,
    text3: <input />,
    //
    itemType4: <SelectItemType id={'itemType4'} options={['activity']} />,
    itemId4: <input />,
    url4: <input />,
    targetUrl4: <TargetSelect />,

    imgSrc4: <input />,
    imgAlt4: <input />,
    imgTitle4: <input />,
    title4: <input />,
    linkText4: <input />,
    text4: <input />,
});

TeaserBoxList.propTypes = {
    // item1
    isVisibleItem1: PropTypes.bool,
    itemType1: PropTypes.string,
    itemId1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url1: PropTypes.string,
    targetUrl1: PropTypes.string,
    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,
    title1: PropTypes.string,
    text1: PropTypes.string,
    linkText1: PropTypes.string,
    // item2
    isVisibleItem2: PropTypes.bool,
    itemType2: PropTypes.string,
    itemId2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url2: PropTypes.string,
    targetUrl2: PropTypes.string,
    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,
    title2: PropTypes.string,
    text2: PropTypes.string,
    linkText2: PropTypes.string,
    // item3
    isVisibleItem3: PropTypes.bool,
    itemType3: PropTypes.string,
    itemId3: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url3: PropTypes.string,
    targetUrl3: PropTypes.string,
    imgSrc3: PropTypes.string,
    imgAlt3: PropTypes.string,
    imgTitle3: PropTypes.string,
    title3: PropTypes.string,
    text3: PropTypes.string,
    linkText3: PropTypes.string,
    // item4
    isVisibleItem4: PropTypes.bool,
    itemType4: PropTypes.string,
    itemId4: PropTypes.string,
    url4: PropTypes.string,
    targetUrl4: PropTypes.string,
    imgSrc4: PropTypes.string,
    imgAlt4: PropTypes.string,
    imgTitle4: PropTypes.string,
    title4: PropTypes.string,
    text4: PropTypes.string,
    linkText4: PropTypes.string,
    // other
    language: PropTypes.string,
    season: PropTypes.string,
};

TeaserBoxList.defaultProps = {
    // item1
    isVisibleItem1: true,
    itemType1: '', // activity
    itemId1: '',
    url1: '#',
    imgSrc1: '',
    imgAlt1: '',
    imgTitle1: '',
    title1: '',
    text1: '',
    linkText1: '',
    targetUrl1: '',
    // item2
    isVisibleItem2: true,
    itemType2: '',
    itemId2: '',
    url2: '#',
    imgSrc2: '',
    imgAlt2: '',
    imgTitle2: '',
    title2: '',
    text2: '',
    linkText2: '',
    targetUrl2: '',
    // item3
    isVisibleItem3: true,
    itemType3: '',
    itemId3: '',
    url3: '#',
    imgSrc3: '',
    imgAlt3: '',
    imgTitle3: '',
    title3: '',
    text3: '',
    linkText3: '',
    targetUrl3: '',
    // item3
    isVisibleItem4: true,
    itemType4: '',
    itemId4: '',
    url4: '#',
    imgSrc4: '',
    imgAlt4: '',
    imgTitle4: '',
    title4: '',
    text4: '',
    linkText4: '',
    targetUrl4: '',
    // other
    language: 'de',
    season: 'winter',
};

export default TeaserBoxList;
